import React, { useState } from 'react'
import { Link } from 'gatsby'
import { get, list, quantity, total } from 'cart-localstorage'
import CartStyles from '../styles/Cart.module.sass'

const Cart = () => {
  const [cart, setCart] = useState( typeof window !== 'undefined' ? list() : null )
  const [expanded, setExpanded] = useState('')
  const [totalQuantity, setTotalQuantity] = useState(calculateTotalQuantity())

  // Prevent Gatsby from prerendering this component
  if (typeof window == 'undefined') {
    return false
  }

  let initialQuantity = 0

  const maxQty = 50

  if (cart && cart.length) {
    cart.map(item => {
      initialQuantity = initialQuantity + item.quantity

      return false
    })
  }

  function calculateTotalQuantity(list) {
    if (list && list.length) {
      let initialQuantity = 0

      list.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else if (cart && cart.length) {
      let initialQuantity = 0

      cart.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else {
      return 0
    }
  }

  // Increase/Decreate quantity
  function changeQty(operation, id) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= maxQty) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())
    setTotalQuantity(calculateTotalQuantity(list()))
  }

  if (cart && cart.length) {
    return (
      <div className={`cart-widget ${(CartStyles.cart || '') + ' ' + expanded}`}>
        <header
          className={CartStyles.header || ''}
          onClick={() =>
            (window.location.pathname = '/checkout')
          }
          onKeyDown={() =>
            (window.location.pathname = '/checkout')
          }
          role="button"
          tabIndex="0"
        >
          <div>
            <b>My Order</b> <i>&euro;{total()}</i>
            <br />
            {(() => {
              if (totalQuantity === 1) {
                return (
                  <span>
                    1 <span className="is-hidden-mobile">delicious</span>{' '}
                    bird
                  </span>
                )
              } else if (totalQuantity > 1) {
                return (
                  <span>
                    {totalQuantity}{' '}
                    <span className="is-hidden-mobile">delicious</span> birds
                  </span>
                )
              }
            })()}
          </div>
          {(() => {
            if (expanded === 'is-expanded') {
              return (
                <button
                  className={`button is-transparent ${CartStyles.buttonClose ||
                    ''}`}
                >
                  X
                </button>
              )
            } else {
              return (
                <Link
                  to="/checkout"
                  className={`button is-transparent ${CartStyles.buttonExpand ||
                    ''}`}
                >
                  View Order &amp; Pay
                </Link>
              )
            }
          })()}
        </header>
        <div
          className={
            (CartStyles.cartBody || '') +
            ' ' +
            (expanded !== 'is-expanded' && 'is-hidden')
          }
        >
          <table className={'table ' + (CartStyles.table || '')}>
            <thead>
              <tr>
                <td>Product</td>
                <td>Price</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {cart.map(item => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>&euro;{item.price}</td>
                    <td>
                      <span
                        className={`tag ${CartStyles.decrease || ''}`}
                        onClick={() => {
                          changeQty('decrease', item.id)
                        }}
                        onKeyDown={() => {
                          changeQty('decrease', item.id)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        x
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className={CartStyles.actions || ''}>
            <em className={CartStyles.price}>
              Total<strong>&euro;{total()}</strong>
            </em>
            <Link
              to="/checkout"
              className={`button is-primary ${CartStyles.pay || ''}`}
            >
              Pay Now
            </Link>
          </div>
        </div>
      </div>
    )
  } else {
    return <div className={`cart-widget ${CartStyles.cartEmpty}`}>Your cart is empty.</div>
  }
}

export default Cart
