import React, { useState } from 'react'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string} className
 * @param {string} buttonClass
 * @param {boolean} formOnly Toggles top info section | Default: false
 */
const ContactForm = props => {
  const initialEmailSubjectString = 'Sandwich Platter Enquiry'

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  return (
    <div
      className={`form-container ${ContactFormStyles.form} ${props.className ||
        ''}`}
    >
      <div>
        {(props.formOnly !== true || props.formOnly !== 'true') && (
          <>
            <span className={`title ${ContactFormStyles.title || ''}`}>
              Get a Quote Today
            </span>
            {/* <p>
              ...
            </p> */}
          </>
        )}

        <form
          name="KingswoodTurkeys Enquiry"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="first-name"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="KingswoodTurkeys Enquiry" />
          <input type="hidden" name="subject" value={emailSubject} />
          <div hidden>
            <label>
              Trap for non-humans: <input name="first-name" />
            </label>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="name" hidden>
                Name
              </label>
              <input
                className="input"
                type={'text'}
                name={'name'}
                id={'name'}
                required={true}
                placeholder={'Your Name'}
                onChange={e => handleSetEmailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="email" hidden>
                Email
              </label>
              <input
                className="input"
                type={'email'}
                name={'email'}
                id={'email'}
                required={true}
                placeholder={'Your E-Mail'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="phone" hidden>
                Phone
              </label>
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                id={'phone'}
                required={true}
                placeholder={'Phone Number'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="message" hidden>
                Message
              </label>
              <textarea
                className="textarea"
                name={'message'}
                id={'message'}
                required={true}
                placeholder={'Order details'}
              />
            </div>
          </div>
          <div className="field">
            <button
              className={`button is-large is-fullwidth ${
                ContactFormStyles.submit
              } ${props.buttonClass ? props.buttonClass : 'is-primary'}`}
              type="submit"
            >
              Get a Quote
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
