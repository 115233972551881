import React from 'react'

/**
 * Add a title-like heading
 *
 * @param {string} text
 */
const FauxTitle = props => {
  return <strong className="title is-2">{props.text}</strong>
}

export default FauxTitle
