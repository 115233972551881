import React from 'react'
import SiteMetadata from '../SiteMetadata'
import PhoneStyles from '../../styles/Phone.module.sass'

/**
 * Display a phone number link
 *
 * @param {string} class
 * @param {string} text
 */
const Phone = props => {
  const { phone } = SiteMetadata()

  return (
    <a
      href={`tel:${phone}`}
      className={`phone ${props.class || ''} ${PhoneStyles.phone || ''}`}
    >
      {props.text || `${phone}`}
    </a>
  )
}

export default Phone
