import React from 'react'

/**
 * Add an anchor link
 *
 * @param {string} id
 */
const Anchor = props => {
  return <span id={props.id} className="anchor"></span>
}

export default Anchor
